<script>
  import { audioIsPlaying, currentTime } from "../stores";
  export let intro_copy, body_copy, cta;
</script>

<div class="landing">
  <div class="landing-intro">
    <div class="serial-logo">
      <img src="{NYTG.ASSETS}/serial-logo.svg" alt="Serial Productions Logo." />
    </div>
    <div class="intro-copy">
      <h1>{@html intro_copy}</h1>
    </div>
        {#if cta}
      <div class="cta">{@html cta}</div>
    {/if}
  </div>
  <div class="landing-body">
    <h2>{@html body_copy}</h2>
  </div>
</div>

<style>
  /*** intro copy ***/
  .landing {
    padding: 15vh 0;
  }

  .landing-intro {
    max-width: 600px;
    margin-bottom: 6vh;
  }

  .serial-logo {
    width: 100%;
    max-width: 180px;
    margin-bottom: 1em;
  }

  .intro-copy h1 {
    display: inline;
    font-family: "nyt-karnak", sans-serif;
    font-weight: bold;
    letter-spacing: 0.01em;
  }

  /*** body copy ***/
  .landing-body {
    max-width: 540px;
  }

  /*** media queries ***/
  @media screen and (min-width: 890px) {

    .landing {
      padding: 25vh 0 15vh;
    }

    .serial-logo {
      margin-bottom: 3vh;
    }

    .landing-body {
      max-width: 633px;
    }
  }

  .cta {
    /* display: flex; */
    /* gap: 0.6rem; */
    color: rgba(245, 245, 245, 0.75);
    background: rgba(245, 245, 245, 0.15);
    padding: 0.5rem 0.75rem;
    letter-spacing: 0.025em;
    border-radius: 5px;
    max-width: fit-content;
    margin: 3vh 0 6vh;
    font-size: 1rem;
    font-family: nyt-franklin, helvetica, arial, sans-serif;
  }

  .cta :global(a) {
    color: rgba(245, 245, 245, 0.75);
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }

  .cta :global(a:hover) {
    text-decoration: none;

  }


</style>
