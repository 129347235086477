<script>
  import { onMount } from "svelte";
  // page sections
  import Landing from "./sections/Landing.svelte";
  import ShowList from "./sections/ShowList.svelte";
  import TeamList from "./sections/TeamList.svelte";
  import Awards from "./sections/Awards.svelte";
  import Contact from "./sections/Contact.svelte";
  // props
  export let cta,
    intro_copy, // copy
    body_copy,
    awards_copy,
    contact_copy,
    audio_timestamps,
    // background_squares, // interactive
    shows, // page content
    newsletter_copy,
    podcast_page_cta_copy,
    team_members;
</script>

<main>
  <!-- <InteractiveBackground {background_squares} /> -->
  <div class="page-content">
    <Landing {intro_copy} {body_copy} {audio_timestamps} {cta} />
    <ShowList {shows} {podcast_page_cta_copy} />
    <TeamList {team_members} />
    <Awards {awards_copy} />
    <Contact {contact_copy} {newsletter_copy} />
  </div>
</main>

<style>
  main {
    background: #272324;
    color: #f5f5f5;
    font-size: 1.25rem;
    padding: 0 25px;
    position: relative;
  }

  .page-content {
    max-width: 1000px;
    margin: 0 auto;
  }
</style>
