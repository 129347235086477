<script>
  export let awards_copy;
</script>

<div class="awards">
  <h1 class="title">Awards</h1>
  <p>{awards_copy}</p>
</div>

<style>
  .awards .title {
    padding-bottom: .75rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid rgba(245, 245, 245, 0.6);
  }

  @media screen and (min-width: 890px) {
    .awards p {
      width: 50%;
      max-width: 700px;
    }
  }
</style>
