<script>
  export let shows, podcast_page_cta_copy;
</script>

<div class="show-list">
  {#each shows as show}
    <div class="show-item">
      <section>
        <h1 class="title">{@html show.title_html}</h1>
        <img
          src="{NYTG.ASSETS}/pod_images/{show.image}"
          alt="Cover image for {show.title}."
        />
      </section>
      <section>
        <p class="blurb">
          {@html show.blurb}
        </p>
        <p class="nyt-page-link">
          <a
            href={show.podcast_page_url
              ? show.podcast_page_url
              : "https://nytimes.com/"}>{podcast_page_cta_copy}</a
          >
        </p>
        <br />
        <div class="cta">
          <p>Listen on:</p>
          <p>
            <a href={show.apple_podcasts_url}>Apple Podcasts</a>,
            <a href={show.spotify_url}>Spotify</a> or
            <a href={show.amazon_music_url}>Amazon Music</a>
          </p>
        </div>
      </section>
    </div>
  {/each}
</div>

<style>
  .show-list {
    padding-bottom: 20vh;
  }

  .show-item {
    padding: 1.25rem 0 3rem;
    margin: 1.25rem auto;
    border-top: 1px solid rgba(245, 245, 455, 0.6);
  }

  .show-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .show-item section:first-child {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .show-item img {
    display: block;
    max-width: 150px;
  }

  .show-item h1 {
    max-width: 450px;
  }

  .show-item p {
    max-width: 550px;
  }

  .blurb {
    margin: 0.25rem 0;
    line-height: 1.3;
    font-weight: 300;
    letter-spacing: .3px;
  }

  .show-item .cta {
    /* display: flex; */
    /* gap: 0.6rem; */
    text-transform: uppercase;
    color: rgba(245, 245, 245, 0.75);
    background: rgba(245, 245, 245, 0.15);
    margin-top: 2.5rem;
    padding: 0.5rem 0.75rem;
    letter-spacing: 0.1em;
    border-radius: 5px;
    max-width: fit-content;
  }

  .show-item .cta p {
    line-height: 1.5;
  }

  .show-item .cta p:first-child {
    flex-basis: 130px;
  }

  .show-item .cta a {
    color: #f5f5f5;
    text-decoration: underline;
  }

  .nyt-page-link {
    display: inline;
    margin-top: 1rem;
    color: rgba(245, 245, 245, 0.6);
  }

  .nyt-page-link a {
    color: inherit;
  }

  .nyt-page-link a:hover {
    color: rgba(245, 245, 245, 1);
  }

  @media screen and (min-width: 640px) {
    .show-item section:first-child {
      display: grid;
      grid-template-columns: 1fr 80px;
      align-items: center;
    }
  }

  @media screen and (min-width: 1050px) {
    .show-item {
      display: grid;
      grid-template-columns: 1fr 400px;
      grid-gap: 2rem;
    }

    .show-item img {
      margin-top: 1.25rem;
    }

    .show-item section:first-child {
      display: block;
    }

    .show-item section:last-child {
      justify-self: right;
    }
  }
</style>
