<script>
  export let contact_copy, newsletter_copy;
</script>

<div class="contact">
  <div class="newsletter-content">
    {@html newsletter_copy}
  </div>
  <div class="contact-content">
    {@html contact_copy}
  </div>
</div>

<style>
  .contact {
    padding: 2rem 0 25vh;
    margin: 17.5vh 0 0;
    border-top: 1px solid rgba(245, 245, 245, 0.6);
  }

  .contact :global(h2) {
    margin: 0;
  }


  .contact :global(br) {
    line-height: 0.5;
  }

  .newsletter-content {
    margin-bottom: 3rem;
  }

  .contact-content {
    max-width: 600px;
  }

  .contact :global(a) {
    color: inherit;
    opacity: 0.9;
  }

  .contact :global(a):hover {
    opacity: 1;
  }
</style>
