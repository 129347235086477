<script>
  export let team_members;
</script>

<div class="team-list">
  <h1 class="title">Team</h1>
  <div class="member-grid">
    {#each team_members as { name, title }}
      <div class="member">
        <p>{name}</p>
        <span>{title}</span>
      </div>
    {/each}
  </div>
</div>

<style>
  .team-list {
    /* max-width: 540px; */
    padding-bottom: 20vh;
  }

  .title {
    padding-bottom: .75rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid rgba(245, 245, 245, 0.6);
  }

  .member-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem 0;
  }

  .member p {
    margin-bottom: 0.25rem;
  }
  .member span {
    font-weight: 200;
  }

  @media screen and (max-width: 500px) {
    .member-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 2rem 1rem;
    }
  }

  @media screen and (min-width: 890px) {
    .team-list {
      /* max-width: none; */
      /* width: calc(100% - 310px); */
    }
  }
</style>
